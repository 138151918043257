
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CartModule from '../../store/modules/CartModule';
import { WebCart, WebCartCurrentDelivery } from '../../models/Cart';
import FreeDeliveryNotice from '../FreeDeliveryNotice.vue';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import isEqual from 'lodash/isEqual';

@Component({
    name: 'TopCart',
    components: {
        FreeDeliveryNotice,
    },
})
export default class TopCart extends Vue {
    @Prop({ type: Number, required: false, default: 0 }) private itemCount: number;
    @Prop({ type: String, required: true }) private primaryLabel: string;
    @Prop({ type: String, required: false, default: "" }) private deliveryText: string;

    private deliveryChanged = false;

    get checkoutLink(): string {
        return (window as any).CbxCheckoutpage;
    }

    get isOpen(): boolean {
        return CartModule.IS_OPEN;
    }

    get cart(): WebCart {
        return CartModule.CART;
    }

    get cartItemCount(): number {
        return CartModule.CART_LOADED ? CartModule.CART?.itemcount : this.itemCount;
    }

    get delivery(): WebCartCurrentDelivery {
        return CartModule.CART_CURRENT_DELIVERY;
    }

    get freeDeliveryPossible(): boolean {
        return this.delivery?.freedeliverypossible ?? false;
    }

    private clickCartPreview() {
        if (!CartModule.IS_OPEN) this.toggleCartPreview();
        else window.location.href = this.checkoutLink;
    }

    private toggleCartPreview() {
        CartModule.SET_IS_OPEN(!CartModule.IS_OPEN);
    }

    @Watch('cart')
    private onCartChange(n: any, o: any) {
        if (!this.$root.$data.cartLoaded) return;
        if (isEqual(n, o)) return;
        if (CartModule.CART_CURRENT_DELIVERY_LOADED)
            this.deliveryChanged = true;

        CartModule.GET_CART_CURRENT_DELIVERY();
            CartModule.GET_CART_CURRENT_FREE_SAMPLE();
        if (CbxVariablesModule.VARIABLES.enableFreeGifts)
            CartModule.GET_CART_CURRENT_FREE_GIFT();
    }
}
